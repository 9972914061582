var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ExportTracksPage", {
    attrs: {
      "tracks-list-route-name": _vm.tracksListRouteName,
      "export-tracks-function": _vm.exportTracksFunction
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }